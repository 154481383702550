import React from 'react'

export default function Footer() {
  return (
    <div>
      <div className='text-nowrap  d-md-flex p-3' style={{justifyContent:'space-between',backgroundColor:"black"}}>

      <div className="text-center text-white">
        <h4><i class="bi bi-person-rolodex h4" style={{color:"#1b7dff"}}></i> Contact</h4>
        <p className='text-center'> <i class="bi bi-envelope h6" ></i><a className='text-white text-decoration-none h6' href="mailto:support@harrvi.com"> support@harrvi.com</a> <br/> <i class="bi bi-phone h6"></i>+91 9008378975</p>
      </div>

      <div className='text-white'>
        <h4><i class="bi bi-geo-alt h4" style={{color:"#1b7dff"}}></i>Address</h4>
        <p>43, 7th Cross Rd, Bhagyalakshmi Avenue,<br/>Rukmaiah Layout, Hulimavu,<br/>Bengaluru, Karnataka<br/> 560076</p>
      </div>

      <div  style={{padding:"10px"}}>
       <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.4466863592106!2d77.60971647464913!3d12.878973687427887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14d4be490a49%3A0xdd822833a84d7ab9!2s43%2C%207th%20Cross%20Rd%2C%20Bhagyalakshmi%20Avenue%2C%20Rukmaiah%20Layout%2C%20Hulimavu%2C%20Bengaluru%2C%20Karnataka%20560076!5e0!3m2!1sen!2sin!4v1720691850855!5m2!1sen!2sin"
        width="auto" height="150" style={{borderRadius:"10px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='office_place'></iframe></div>
       </div>

    </div>
  )
}
